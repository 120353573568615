import { useNavigate } from 'react-router-dom'

import {
  LandingPageName,
  trackClickLandingPages,
} from '@/core/services/trackLandingPages'
import { LineListing } from '@/pages/common/Landing/components/LineListing'
import { route } from '@/pages/routes'

import {
  LinesContainer,
  MainTitle,
  MaxWidthContainer,
  TrialButton,
} from '../../AllinOne.styled'
import { LightSpan, Main, TagLine } from './educationJourney.styled'

const lines: string[] = ['whatToTeach', 'knowing', 'organizing']

export function EducationJourney() {
  const navigate = useNavigate()

  const signUp = () => {
    trackClickLandingPages.handleClick(LandingPageName.Allinone)
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <MainTitle>
          <LightSpan>The</LightSpan> #1 Easiest to Use All-in-One{' '}
          <LightSpan>Homeschool Program</LightSpan>
        </MainTitle>

        <TagLine>Struggling with...</TagLine>
        <LinesContainer>
          <LineListing lines={lines} color="Orange" />
        </LinesContainer>
        <TagLine style={{ marginTop: '1rem' }}>
          Experience Peace of Mind - You’ve Got It All Covered!
        </TagLine>

        <TrialButton onClick={signUp}>
          Start 7-day Free Trial
          <span>*Cancel Anytime | 100% Refunds</span>
        </TrialButton>
      </Main>
    </MaxWidthContainer>
  )
}
