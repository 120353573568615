import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Button, Loader } from '@/components'
import type { LandingPageName } from '@/core/services/trackLandingPages'
import { trackClickLandingPages } from '@/core/services/trackLandingPages'
import {
  MsCategory,
  SubscriptionPlanInterval,
  useSubscriptionPlansMscBasedQuery,
} from '@/graphql/generated/types'
import { comboBullets } from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards'
import {
  CrossedOutPrice,
  FeaturesTitle,
  ListItem,
  ListSec,
  PlanPostPriceText,
  PlanPrice,
  PlanTitle,
  PriceTitle,
  SeeLessMoreButton,
} from '@/pages/parent/dashboard/Home/components/SubscribeCard/MSCSubscriptionCards/MSCSubscriptionCards.styled'
import { LoadingContainer } from '@/pages/parent/dashboard/Home/components/SubscribeCard/SubscribeCard.styled'
import { route } from '@/pages/routes'
import {
  containerMaxWidth,
  MaxWidthContainer as StyledMaxWidthContainer,
} from '@/styles/components'
import { media } from '@/styles/media'

const MaxWidthContainer = styled(StyledMaxWidthContainer)`
  padding: 0 2rem;
  max-width: ${containerMaxWidth};
  margin: 2rem auto;

  ${media.mobile(
    () => css`
      padding: 0 1rem;
    `,
  )}
`

const SignUpLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.big};
  text-decoration: none;
  color: ${({ theme }) => theme.color.white};
  background: linear-gradient(
    98.27deg,
    ${({ theme }) => theme.color.violetMagican} 1.4%,
    ${({ theme }) => theme.color.kingTriton} 183.31%
  );
  padding: 0.2rem 1rem;
  margin: 0px 0px 16px 16px;
  border-radius: 16px;
  display: inline-block;

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.big};
    `,
  )}
`

const Title = styled.div`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.big};
  font-size: ${({ theme }) => theme.fontSize.gigantic};

  ${media.mobile(
    () => css`
      font-weight: ${({ theme }) => theme.fontWeight.big};
      font-size: ${({ theme }) => theme.fontSize.great};
    `,
  )}
`

const PriceDiv = styled.div`
  padding-top: 2rem;
`

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #4f4f4f;

  ${media.mobile(
    () => css`
      flex-direction: column;
      gap: 1rem;
    `,
  )}
`

const PlanContainer = styled.div<{
  index: string
  isFeatured: boolean
}>`
  border: 1px solid #ccc;
  padding: 0;
  width: 30%;
  margin: 10px;
  border: ${({ isFeatured, theme }) =>
    (isFeatured && '5px ' + theme.color.purpleHeart + ' solid') ||
    '1px solid #ccc'};
  justify-items: center;

  ${media.mobile(
    () => css`
      width: 100%;
      margin: 0;
    `,
  )}
`

const TagLine = styled.p`
  margin: auto;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.bigger};
`

const LightSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`

const DarkSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.big};
`

const DarkAndUnderlineSpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.big};
  text-decoration: underline;
`

const TrialButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 24px;
  font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  line-height: 1.5;
  margin: 1rem auto;
  border-radius: 2rem;

  span {
    display: block;
    font-size: ${({ theme }) => theme.landingSpecialFontSize.tiny};
  }
`

type PricingComponentProps = {
  viaLandingPage?: LandingPageName
}

export function PricingComponent({ viaLandingPage }: PricingComponentProps) {
  const { t } = useTranslation('parent', {
    keyPrefix: 'home',
  })
  const { t: tl } = useTranslation('landing')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isPricing = pathname === '/pricing'

  const { data: dataMSCPlans, loading: loadingMSCPlans } =
    useSubscriptionPlansMscBasedQuery()

  const [showAll, setShowAll] = useState(false)

  const handleToggle = () => {
    setShowAll(!showAll)
  }

  const signUp = () => {
    if (viaLandingPage) {
      trackClickLandingPages.handleClick(viaLandingPage)
    }

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  // const extractPrice = (priceString: string) => {
  //   const match = /\$([\d.]+)/.exec(priceString)
  //   return match ? parseFloat(match[1]) : 0
  // }

  // Not showing Future Readiness
  const filteredPlans = dataMSCPlans?.subscriptionPlansMSCBased

  const monthlyPlan = dataMSCPlans?.subscriptionPlansMSCBased.find(
    (plan) => plan.interval === SubscriptionPlanInterval.Month,
  )
  let totalFor12Months = 0

  if (monthlyPlan) {
    const monthlyPrice = parseFloat(monthlyPlan.price.replace(/[^0-9.]/g, ''))
    totalFor12Months = parseFloat((monthlyPrice * 12).toFixed(2))
  }

  // let corePlanPrice = 0
  // let futureReadinessPlanPrice = 0

  // filteredPlans?.forEach((plan) => {
  //   if (plan.MSCategory === 'Core') corePlanPrice = extractPrice(plan.price)
  //   if (plan.MSCategory === 'FutureReadiness')
  //     futureReadinessPlanPrice = extractPrice(plan.price)
  // })

  // const comboIdealPrice =
  //   Math.ceil((corePlanPrice + futureReadinessPlanPrice) * 100) / 100

  return (
    <MaxWidthContainer>
      <Title>
        {isPricing && tl('pricing')}
        {!isPricing && 'Affordable Plan!'}
      </Title>
      {isPricing && <TagLine>{tl('getExactly')}</TagLine>}

      <PriceDiv>
        {!loadingMSCPlans && (
          <PlansContainer>
            {filteredPlans?.slice(0, 3).map((plan, index) => (
              <PlanContainer
                key={plan._id}
                index={String(index)}
                isFeatured={
                  plan.MSCategory === 'Core' &&
                  plan.interval === SubscriptionPlanInterval.Year
                }
              >
                <PlanTitle
                  bgColor={index === 0 ? '0' : index === 1 ? '1' : '2'}
                >
                  {plan.interval === SubscriptionPlanInterval.Year && (
                    <PriceTitle>{plan.postPriceText}</PriceTitle>
                  )}

                  <PlanPrice>
                    {plan.price}
                    {plan.interval === SubscriptionPlanInterval.Year && (
                      <>
                        {' '}
                        <CrossedOutPrice>
                          <del>${totalFor12Months}</del>
                        </CrossedOutPrice>
                      </>
                    )}

                    {/* {plan.MSCategory === 'Combo' && (
                      <>
                        {' '}
                        <CrossedOutPrice>
                          <del>${comboIdealPrice}</del>
                        </CrossedOutPrice>
                      </>
                    )} */}
                  </PlanPrice>
                  {plan.MSCategory === MsCategory.Core && (
                    <p>
                      <LightSpan>
                        The perfect plan for families looking for a{' '}
                        <DarkSpan>complete</DarkSpan> home learning solution.
                      </LightSpan>
                    </p>
                  )}
                </PlanTitle>
                {plan.MSCategory === MsCategory.Core && (
                  <PlanPostPriceText>
                    Enjoy access to all courses in Unlimited access to the
                    complete Schoolio Library, covering
                    <DarkSpan> Math, Language Arts, Science,</DarkSpan> and{' '}
                    <DarkSpan>Social Studies,</DarkSpan> including{' '}
                    <DarkSpan>interest-based electives,</DarkSpan>{' '}
                    <DarkAndUnderlineSpan>PLUS</DarkAndUnderlineSpan> our{' '}
                    <DarkSpan>Future-Readiness Library</DarkSpan>{' '}
                  </PlanPostPriceText>
                )}

                {!isPricing && (
                  <TrialButton onClick={signUp}>
                    Start 7-day Free Trial
                    <span>*Cancel Anytime | 100% Refunds</span>
                  </TrialButton>
                )}
                {isPricing && <SignUpLink href="/">{tl('signUp')}</SignUpLink>}

                {plan.MSCategory === MsCategory.Core && (
                  <ListSec>
                    {showAll && (
                      <>
                        <FeaturesTitle>Features</FeaturesTitle>

                        {comboBullets.map((bullet) => (
                          <ListItem key={bullet}>{t(`${bullet}`)}</ListItem>
                        ))}
                      </>
                    )}

                    <SeeLessMoreButton onClick={handleToggle}>
                      {showAll ? 'See less...' : 'See more features...'}
                    </SeeLessMoreButton>
                  </ListSec>
                )}
              </PlanContainer>
            ))}
          </PlansContainer>
        )}

        {loadingMSCPlans && (
          <LoadingContainer>
            <Loader color="grape" />
          </LoadingContainer>
        )}
      </PriceDiv>
    </MaxWidthContainer>
  )
}
