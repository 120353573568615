export enum LandingPageName {
  Welcome = 'welcome',
  WelcomeCanada = 'welcomecanada',
  Neurodivergence = 'neurodivergence',
  Allinone = 'allinone',
  Secular = 'secular',
  Learningmodes = 'learningmodes',
  Autograding = 'autograding',
  Customcourses = 'customcourses',
}

export class TrackClickLandingPages {
  private get trackLandingPageClickKey() {
    return 'clickFromLandingPage'
  }

  handleClick(landingPageName: string) {
    localStorage.setItem(this.trackLandingPageClickKey, landingPageName)
  }

  checkIfRecordExist() {
    const record = localStorage.getItem(this.trackLandingPageClickKey)
    return Boolean(record)
  }

  getTrackedLandingPageClick() {
    return this.checkIfRecordExist()
      ? localStorage.getItem(this.trackLandingPageClickKey)
      : ''
  }

  clearRecord() {
    localStorage.removeItem(this.trackLandingPageClickKey)
  }
}

export const trackClickLandingPages = new TrackClickLandingPages()
