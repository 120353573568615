import * as Joi from 'joi'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { mBytesToBytes } from '@/core/utils/mBytesToBytes'
import type {
  PdfType,
  ResourceType,
} from '@/pages/parent/dashboard/CourseDetails/components/AddEditLessonModal'

export const validationLengths = {
  nameMinLength: 2,
  usernameMinLength: 5,
  passwordMinLength: 6,
  languagesMinLength: 0,
  lessonDurationMin: 5,
  lessonDurationMax: 360,
  lessonPdfMaxSize: 20, // in mb
  lessonResourceThumbMaxSize: 1, // in mb
  userProfilePictureMaxSize: 2, // in mb
  courseNameMinLength: 4,
  courseNameMaxLength: 30,
  quizScoreMin: 0,
  quizScoreMax: 100,
}

const validationErrorMessages = {
  email: 'validation.email',
  username: 'validation.username',
  usernameMin: 'validation.usernameMin',
  required: 'validation.required',
  password: 'validation.password',
  repeatPassword: 'validation.repeatPassword',
  name: 'validation.name',
  nameMin: 'validation.nameMin',
  courseNameMin: 'validation.courseNameMin',
  courseNameMax: 'validation.courseNameMax',
  arrayMin: 'validation.arrayMin',
  languages: 'validation.languages',
  phoneInvalid: 'validation.phoneInvalid',
  number: 'validation.number',
  lessonDurationMin: 'validation.lessonDurationMin',
  lessonDurationMax: 'validation.lessonDurationMax',
  lessonPdfMaxSize: 'validation.lessonPdfMaxSize',
  lessonResourceThumbMaxSize: 'validation.lessonResourceThumbMaxSize',
  userProfilePictureMaxSize: 'validation.userProfilePictureMaxSize',
  noLessDate: 'validation.noLessDate',
  date: 'validation.date',
  atLeastOneDay: 'validation.atLeastOneDay',
  url: 'validation.url',
  areRequired: 'validation.areRequired',
  quizScoreMin: 'validation.quizScoreMin',
  quizScoreMax: 'validation.quizScoreMax',
}

export const validators = {
  string: () => Joi.string().allow(''),
  editorState: () =>
    Joi.object({
      _immutable: Joi.object().unknown(true),
    }).unknown(true),
  courseName: () =>
    validators
      .stringRequired()
      .min(validationLengths.courseNameMinLength)
      .max(validationLengths.courseNameMaxLength)
      .messages({
        'string.empty': validationErrorMessages.required,
        'string.min': validationErrorMessages.courseNameMin,
        'string.max': validationErrorMessages.courseNameMax,
      }),
  stringRequired: () =>
    Joi.string().required().messages({
      'string.empty': validationErrorMessages.required,
    }),
  atLeastOneDay: () =>
    Joi.object()
      .custom((object: Record<string, boolean>, helper) => {
        if (
          object.monday ||
          object.tuesday ||
          object.wednesday ||
          object.thursday ||
          object.friday ||
          object.saturday ||
          object.sunday
        ) {
          return {
            monday: object.monday,
            tuesday: object.tuesday,
            wednesday: object.wednesday,
            thursday: object.thursday,
            friday: object.friday,
            saturday: object.saturday,
            sunday: object.sunday,
          }
        }

        return helper.message({
          custom: 'custom',
        })
      })
      .messages({
        custom: validationErrorMessages.atLeastOneDay,
      }),
  option: () =>
    Joi.object().keys({
      label: Joi.string().required(),
      value: Joi.string().required(),
    }),
  optionOptional: () =>
    Joi.object().keys({
      label: Joi.string().allow(''),
      value: Joi.string().allow(''),
    }),
  optionRequired: () =>
    validators.option().required().messages({
      'any.required': validationErrorMessages.required,
    }),
  creatableOption: () =>
    validators.option().keys({
      __isNew__: Joi.boolean().optional(),
    }),
  creatableOptionRequired: () =>
    validators.creatableOption().required().messages({
      'any.required': validationErrorMessages.required,
    }),
  optionsInArrayRequired: (
    min = 1,
    validationMessage = validationErrorMessages.arrayMin,
  ) =>
    Joi.array().items(validators.option()).min(min).messages({
      'array.min': validationMessage,
    }),
  optionsInArray: () => Joi.array().items(validators.option()),
  languages() {
    return this.optionsInArrayRequired(
      validationLengths.languagesMinLength,
      validationErrorMessages.languages,
    )
  },
  arrayStringRequired: () => Joi.array().items(Joi.string()).min(1),
  booleanRequired: () => Joi.boolean().required(),
  boolean: () => Joi.boolean().optional(),
  booleanTrueRequired() {
    return this.booleanRequired().valid(true)
  },
  email: () =>
    Joi.string().email({ tlds: false }).required().messages({
      'string.email': validationErrorMessages.email,
      'string.empty': validationErrorMessages.required,
    }),
  emailOptional: () => validators.email().optional().allow(''),
  username: () =>
    Joi.string()
      .alphanum()
      .min(validationLengths.usernameMinLength)
      .required()
      .messages({
        'string.min': validationErrorMessages.usernameMin,
        'string.alphanum': validationErrorMessages.username,
        'string.empty': validationErrorMessages.required,
      }),
  name: () =>
    Joi.string()
      .regex(/^[a-zA-Z\s-]*$/)
      .min(validationLengths.nameMinLength)
      .required()
      .messages({
        'string.min': validationErrorMessages.nameMin,
        'string.regex': validationErrorMessages.name,
        'string.empty': validationErrorMessages.required,
        'string.pattern.base': validationErrorMessages.name,
      }),
  dateRequired: () =>
    Joi.date().required().messages({
      'date.base': validationErrorMessages.date,
    }),
  dateOfBirth: () =>
    validators.dateRequired().less('now').messages({
      'date.base': validationErrorMessages.date,
      'date.less': validationErrorMessages.noLessDate,
    }),
  password: () =>
    Joi.string().min(validationLengths.passwordMinLength).required().messages({
      'string.empty': validationErrorMessages.required,
      'string.min': validationErrorMessages.password,
    }),
  passwordOptional: () => validators.password().optional().allow('', null),
  repeatPassword: () =>
    Joi.any().valid(Joi.ref('password')).required().messages({
      'any.only': validationErrorMessages.repeatPassword,
    }),
  phoneNumber: () =>
    Joi.string()
      .custom((value: string, helper) => {
        const isValid = isValidPhoneNumber(value)

        if (!isValid) {
          return helper.message({
            custom: 'custom',
          })
        }

        return value
      })
      .messages({
        'string.base': validationErrorMessages.required,
        'string.empty': validationErrorMessages.required,
        custom: validationErrorMessages.phoneInvalid,
      }),
  phoneNumberOptional: () =>
    validators.phoneNumber().optional().allow('', null),
  file: (maxSizeInMb: number, errorMessage: string) =>
    Joi.object()
      .unknown(true)
      .custom((value: File | undefined, helper) => {
        if (!value) {
          return
        }

        if (value.size > mBytesToBytes(maxSizeInMb)) {
          return helper.error('custom')
        }

        return value
      })
      .messages({
        custom: errorMessage,
      }),
  fileRequired: (maxSizeInMb: number, errorMessage: string) =>
    validators.file(maxSizeInMb, errorMessage).required().messages({
      'any.required': validationErrorMessages.required,
      custom: errorMessage,
    }),
  profilePicture: () =>
    validators.file(
      validationLengths.userProfilePictureMaxSize,
      validationErrorMessages.userProfilePictureMaxSize,
    ),
  lesson: {
    duration: () =>
      Joi.number()
        .min(validationLengths.lessonDurationMin)
        .max(validationLengths.lessonDurationMax)
        .required()
        .messages({
          'number.base': validationErrorMessages.number,
          'number.min': validationErrorMessages.lessonDurationMin,
          'number.max': validationErrorMessages.lessonDurationMax,
        }),
    pdfs: () =>
      Joi.array()
        .items(
          Joi.object<PdfType>({
            file: validators.file(
              validationLengths.lessonPdfMaxSize,
              validationErrorMessages.lessonPdfMaxSize,
            ),
            tag: validators.option(),
            id: validators.string(),
            isDeleted: validators.boolean(),
          }).and('file', 'tag'),
        )
        .messages({
          'any.required': validationErrorMessages.required,
          'object.and': validationErrorMessages.areRequired,
        }),
    resources: () =>
      Joi.array().items(
        Joi.object<ResourceType>({
          title: validators.string(),
          url: validators.string().uri().messages({
            'string.uri': validationErrorMessages.url,
          }),
          thumbnail: validators.file(
            validationLengths.lessonResourceThumbMaxSize,
            validationErrorMessages.lessonResourceThumbMaxSize,
          ),
          id: validators.string(),
          isDeleted: validators.boolean(),
        })
          .custom((value: ResourceType, helpers) => {
            if (value.thumbnail && (!value.title || !value.url)) {
              return helpers.error('object.and')
            }

            if ((!value.title && value.url) || (value.title && !value.url)) {
              return helpers.error('object.and')
            }

            return value
          })
          .messages({
            'object.and': validationErrorMessages.areRequired,
          }),
      ),
  },
  referralSourceWebsite: () =>
    Joi.string().when('referralSource', {
      is: Joi.object({
        label: Joi.string().pattern(/website/i),
      }).unknown(true),
      then: validators.stringRequired(),
      otherwise: validators.string(),
    }),

  referralSourceOther: () =>
    Joi.string().when('referralSource', {
      is: Joi.object({
        label: Joi.string().pattern(/other/i),
      }).unknown(true),
      then: validators.stringRequired(),
      otherwise: validators.string(),
    }),
  reportScreenshots: () =>
    Joi.array().items(
      Joi.object({
        blob: Joi.object().unknown(true),
        src: validators.stringRequired(),
      }),
    ),
  reasonOther: () =>
    Joi.string().when('reason', {
      is: Joi.object({
        label: Joi.string().pattern(/other/i),
      }).unknown(true),
      then: validators.stringRequired(),
      otherwise: validators.string(),
    }),
  Quiz: {
    score: () =>
      Joi.number()
        .min(validationLengths.quizScoreMin)
        .max(validationLengths.quizScoreMax)
        .required()
        .messages({
          'number.base': validationErrorMessages.number,
          'number.min': validationErrorMessages.quizScoreMin,
          'number.max': validationErrorMessages.quizScoreMax,
        }),
  },
}
