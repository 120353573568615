import { useEffect } from 'react'

import { adUserManager } from '@/core/services/adUser'
import { LandingPageName } from '@/core/services/trackLandingPages'

import { Footer } from '../Landing/components/Footer'
import { Nav } from '../Landing/components/Nav'
import { Main } from '../Landing/Landing.styled'
import { EducationJourney } from './components/EducationJourney'
import { ImagesComponent } from './components/ImagesComponent/ImagesComponent'

export function Learningmodes() {
  useEffect(() => {
    adUserManager.handleUrl()
  }, [])

  return (
    <Main>
      <Nav viaLandingPage={LandingPageName.Learningmodes} />
      <EducationJourney />
      <ImagesComponent />
      <Footer />
    </Main>
  )
}
