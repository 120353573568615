import { useNavigate } from 'react-router-dom'

import interestBasedLearning from '@/assets/images/landing/1.png'
import calendar from '@/assets/images/landing/2.png'
import schoolioUnique from '@/assets/images/landing/3.png'
import autoGrading from '@/assets/images/landing/4.png'
import funPlay from '@/assets/images/landing/5.png'
import funPlay2 from '@/assets/images/landing/6.png'
import testimonial2 from '@/assets/images/landing/157.png'
import testimonial3 from '@/assets/images/landing/158.png'
import dashboard from '@/assets/images/landing/dashboard.webp'
import emotions from '@/assets/images/landing/image (1).png'
import testimonial1 from '@/assets/images/landing/Secular/116.png'
import subjects from '@/assets/images/landing/Secular/Secular.png'
import {
  LandingPageName,
  trackClickLandingPages,
} from '@/core/services/trackLandingPages'
import { PricingComponent } from '@/pages/common/Pricing/PricingComponent'
import { route } from '@/pages/routes'

import { MaxWidthContainer, TrialButton } from '../../Secular.styled'
import {
  AnchorLink,
  ImageContainer,
  ImageDashboard,
  ImageEmotions,
  IndiImage,
  Main,
  MainTitle,
  MiniTitle,
  ReviewContain,
  SectionImage,
  TestimonialImage,
  Title,
  UserTestimonial,
} from './ImagesComponent.styled'

export function ImagesComponent() {
  const navigate = useNavigate()

  const signUp = () => {
    trackClickLandingPages.handleClick(LandingPageName.Secular)
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    navigate(route.landing())
  }

  return (
    <MaxWidthContainer>
      <Main>
        <SectionImage>
          <MainTitle>What Is Secular Curriculum?</MainTitle>
          <Title>
            Learning materials that are not written or presented through any
            faith-based lens.
          </Title>
          <IndiImage src={subjects} alt="Image" />
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>What does Secular Curriculum Matter?</MainTitle>
          <Title>Diverse Community Representation</Title>
          <Title style={{ marginTop: '2rem' }}>
            All-Voices History and Social Studies
          </Title>
          <Title style={{ marginTop: '2rem' }}>
            Evidence-Based Scientific Learning
          </Title>

          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>Everything You Need for Success!</MainTitle>
          <Title>All Subjects and All Grades in One Place!</Title>
          <IndiImage src={interestBasedLearning} alt="Image" />
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>Scheduling Made Easy!</MainTitle>
          <IndiImage src={calendar} alt="Image" />
          <Title>Dashboard, Grades & Transcripts!</Title>
          <ImageContainer>
            <ImageDashboard src={dashboard} loading="lazy" alt="dashboard" />
            <ImageEmotions src={emotions} loading="lazy" alt="emotions" />
          </ImageContainer>
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>What Makes Schoolio Unique</MainTitle>
          <Title>Create Custom Courses!</Title>
          <IndiImage src={schoolioUnique} alt="Image" />
          <Title>Written Work Auto Grading!</Title>
          <IndiImage src={autoGrading} alt="Image" />
          <MiniTitle>New Features Released Every Week</MiniTitle>
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <MainTitle>Child-Centered Learning</MainTitle>
          <IndiImage
            style={{ marginBottom: '2rem' }}
            src={funPlay}
            alt="Image"
          />
          <MainTitle>Designed for ADHD & Autistic Learners</MainTitle>
          <IndiImage src={funPlay2} alt="Image" />
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>

        <SectionImage>
          <Title>Schoolio Love!</Title>
          <ReviewContain>
            We know you have options when it comes to your home learning
            provider, and we appreciate your trust in us. You can see how we
            compare to{' '}
            <AnchorLink href="https://schoolio.com/competitors/?">
              Competitors
            </AnchorLink>{' '}
            like{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#miacademy">
              MiAcademy
            </AnchorLink>
            ,{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#ixl">
              IXL
            </AnchorLink>
            , and{' '}
            <AnchorLink href="https://schoolio.com/competitors/?#time4learning">
              Time4Learning
            </AnchorLink>{' '}
            so you feel fully informed in your choice.
          </ReviewContain>
          <UserTestimonial>
            <TestimonialImage src={testimonial1} alt="Image" />
          </UserTestimonial>
          <UserTestimonial>
            <TestimonialImage src={testimonial2} alt="Image" />
          </UserTestimonial>
          <UserTestimonial>
            <TestimonialImage src={testimonial3} alt="Image" />
          </UserTestimonial>
          <TrialButton onClick={signUp}>
            Start 7-day Free Trial
            <span>*Cancel Anytime | 100% Refunds</span>
          </TrialButton>
        </SectionImage>
        <PricingComponent viaLandingPage={LandingPageName.Secular} />
      </Main>
    </MaxWidthContainer>
  )
}
