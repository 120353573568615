import { useResetPasswordMutation } from '@graphql/generated/types'
import { joiResolver } from '@hookform/resolvers/joi/dist/joi'
import * as Joi from 'joi'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import eye from '@/assets/icons/eye.svg'
import eyeSlash from '@/assets/icons/eye-slash.svg'
import { Button, Input } from '@/components'
import { useRecaptcha, useTitle } from '@/core/hooks'
import { validators } from '@/core/validation'
import {
  Form,
  FormColumn,
  Inputs,
  SchoolioLogo,
  Title,
} from '@/pages/auth/Auth.styled'
import { route } from '@/pages/routes'

import {
  EyeIcon,
  PasswordContainer,
  PasswordInputContainer,
} from '../SignIn/SignIn.styled'
import { Buttons, Cancel, Success } from './ResetPassword.styled'

type ResetPasswordSchema = {
  password: string
  repeatPassword: string
}

const schema = Joi.object<ResetPasswordSchema>({
  password: validators.password(),
  repeatPassword: validators.repeatPassword(),
})

export function ResetPassword() {
  const { token = '' } = useParams()
  const { t } = useTranslation('resetPassword')
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordSchema>({
    resolver: joiResolver(schema),
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  })

  useTitle('resetPassword')
  const { getToken, isLoading } = useRecaptcha('resetPassword')

  const [resetPassword, { loading, data }] = useResetPasswordMutation()

  const onSubmit = async ({ password }: ResetPasswordSchema) => {
    const recaptchaToken = await getToken()

    resetPassword({
      variables: {
        input: {
          password,
          token,
          recaptchaToken,
        },
      },
    })
  }

  const logoClicked = () => {
    navigate('/')
  }

  return (
    <FormColumn>
      <SchoolioLogo onClick={logoClicked} />
      <Title>{t('setNewPassword')}</Title>
      {data?.resetPassword ? (
        <Success>
          {t('success')}
          <br />
          <Link to={route.signIn()}>{t('backToLogin')}</Link>
        </Success>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Inputs>
            <PasswordContainer>
              <PasswordInputContainer>
                <Input
                  label={t('form.password', { ns: 'signIn' })}
                  placeholder={t('form.passwordPlaceholder', { ns: 'signIn' })}
                  error={errors.password?.message}
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                />
              </PasswordInputContainer>

              <EyeIcon
                src={showPassword ? eyeSlash : eye}
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
              />
            </PasswordContainer>{' '}
            <PasswordContainer>
              <PasswordInputContainer>
                <Input
                  label={t('repeatPassword')}
                  placeholder={t('repeatPassword')}
                  error={errors.repeatPassword?.message}
                  type={showResetPassword ? 'text' : 'password'}
                  {...register('repeatPassword')}
                />
              </PasswordInputContainer>

              <EyeIcon
                src={showResetPassword ? eyeSlash : eye}
                onClick={() => {
                  setShowResetPassword(!showResetPassword)
                }}
              />
            </PasswordContainer>
          </Inputs>
          <Buttons>
            <Button loading={loading || isLoading}>{t('save')}</Button>
            <Cancel
              appearance="outline"
              onClick={() => {
                navigate(route.signIn())
              }}
            >
              {t('cancel')}
            </Cancel>
          </Buttons>
        </Form>
      )}
    </FormColumn>
  )
}
