import { UserRole } from '@graphql/generated/types'
import type { ReactNode } from 'react'
import { Suspense, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { FullscreenLoader } from '@/components'
import { lazyImport } from '@/core/utils/lazyImport'
import { AuthRoute, getAuthRoutes } from '@/pages/auth/authRoutes'
import { ChildRoute, routeKey as childRouteKey } from '@/pages/child/childRoute'
import { CommonRoute, getCommonRoutes } from '@/pages/common/commonRoutes'
import { DashboardRoute } from '@/pages/parent/dashboard/dashboardRoute'
import {
  ParentRoute,
  routeKey as parentRouteKey,
} from '@/pages/parent/parentRoute'
import { useAuthUser } from '@/store/user'

import { AdminRoute, routeKey as adminRouteKey } from './admin/adminRoute'
import AdminRoutes from './admin/AdminRoutes'

const { ParentRoutes } = lazyImport(
  async () => import('@/pages/parent/ParentRoutes'),
  'ParentRoutes',
)

const { ChildRoutes } = lazyImport(
  async () => import('@/pages/child/ChildRoutes'),
  'ChildRoutes',
)

type RoleRoute = {
  rootPath: string
  Routes: () => JSX.Element
  Loader: ReactNode
}

export const AppRoute = {
  ...AuthRoute,
  ...CommonRoute,
  Child: ChildRoute,
  Parent: {
    Dashboard: DashboardRoute,
    ...ParentRoute,
  },
  Admin: AdminRoute,
}

const zIndex = 101

export function AppRoutes() {
  const { isLogged, user } = useAuthUser()

  const logged = isLogged && user?.role

  const fallbackRoute = useMemo(() => {
    if (!logged) {
      return AppRoute.SignIn
    }

    const newusermode = localStorage.getItem('newusermode')
    localStorage.removeItem('newusermode')

    return user?.role === UserRole.Child
      ? `${childRouteKey}${AppRoute.Child.Dashboard}`
      : user?.role === UserRole.Admin
      ? `${adminRouteKey}${AppRoute.Admin.Default}`
      : newusermode
      ? `${parentRouteKey}?newuser=true`
      : `${parentRouteKey}${AppRoute.Parent.Default}`
  }, [logged])

  const roleRoute: { [key in UserRole]: RoleRoute } = {
    [UserRole.Parent]: {
      rootPath: parentRouteKey,
      Routes: ParentRoutes,
      Loader: <FullscreenLoader zIndex={zIndex} />,
    },
    [UserRole.Child]: {
      rootPath: childRouteKey,
      Routes: ChildRoutes,
      Loader: (
        <FullscreenLoader
          zIndex={zIndex}
          color="white"
          backgroundColor="portGore"
        />
      ),
    },
    [UserRole.Admin]: {
      rootPath: adminRouteKey,
      Routes: AdminRoutes,
      Loader: <FullscreenLoader zIndex={zIndex} />,
    },
  }

  const getRoleRoutes = () => {
    if (!user?.role) {
      return null
    }

    const { rootPath, Routes, Loader }: RoleRoute = roleRoute[user.role]

    return (
      <Route
        path={`${rootPath}/*`}
        element={
          <Suspense fallback={Loader}>
            <Routes />
          </Suspense>
        }
      />
    )
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate to={fallbackRoute} />} />
      {getCommonRoutes()}
      {!logged && getAuthRoutes()}
      {logged && getRoleRoutes()}
    </Routes>
  )
}
