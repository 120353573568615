import { useTranslation } from 'react-i18next'

import facebook from '@/assets/images/landing/facebook.svg'
import instagram from '@/assets/images/landing/instagram.svg'
import tikTok from '@/assets/images/landing/tik-tok.svg'
import youtube from '@/assets/images/landing/youtube.svg'
import logo from '@/assets/images/logo-word.png'
import { schoolioBookStoreUrl } from '@/core/const'

import {
  FlexContainer,
  Hr,
  Link,
  Links,
  Logo,
  Main,
  MiddleContainer,
  Rights,
  SocialLink,
  SocialMediaContainer,
  SvgIcon,
  TitleLinks,
} from './Footer.styled'

type Media = {
  url: string
  svg: string
}

type GeneralLink = {
  url: string
  title: string
}

const socialMedia: Media[] = [
  {
    url: 'https://www.youtube.com/@schooliolearning',
    svg: youtube,
  },
  {
    url: 'https://www.instagram.com/schooliohomeschooling/',
    svg: instagram,
  },
  {
    url: 'https://www.facebook.com/schooliolearning/',
    svg: facebook,
  },
  {
    url: 'https://www.tiktok.com/@schooliolearning',
    svg: tikTok,
  },
]

const generalLinks: GeneralLink[] = [
  {
    url: `${schoolioBookStoreUrl}`,
    title: 'Companion Books',
  },
  {
    url: `${schoolioBookStoreUrl}/blog`,
    title: 'Blog',
  },
  {
    url: `${schoolioBookStoreUrl}/aboutus`,
    title: 'About Us',
  },
  {
    url: '/privacy-policy',
    title: 'Privacy Policy',
  },
  {
    url: `${schoolioBookStoreUrl}/course-catalog`,
    title: 'Course Catalog',
  },
]

const featuredLinks: GeneralLink[] = [
  {
    url: `/neurodivergence`,
    title: 'Neurodivergent-Friendly Design',
  },
  {
    url: `/allinone`,
    title: 'All-in-One Learning',
  },
  {
    url: '/learningmodes',
    title: 'Learning Modes',
  },
  {
    url: `/autograding`,
    title: 'Auto-Grading Tool',
  },
  {
    url: `/customcourses`,
    title: 'Custom Courses',
  },
]

export function Footer() {
  const { t } = useTranslation('landing')

  return (
    <Main>
      <MiddleContainer>
        <FlexContainer>
          <Logo src={logo} />

          <Links>
            <TitleLinks>Quick Links</TitleLinks>
            {generalLinks.map(function (link) {
              return (
                <Link key={link.title} target="_blank" href={link.url}>
                  {`${link.title}`}
                </Link>
              )
            })}
          </Links>

          <Links>
            <TitleLinks>Program & Features</TitleLinks>
            {featuredLinks.map(function (link) {
              return (
                <Link key={link.title} href={link.url}>
                  {`${link.title}`}
                </Link>
              )
            })}
          </Links>

          <SocialMediaContainer>
            {socialMedia.map(function (socialMedia) {
              return (
                <SocialLink
                  key={socialMedia.url}
                  target="_blank"
                  href={socialMedia.url}
                >
                  <SvgIcon src={socialMedia.svg} />
                </SocialLink>
              )
            })}
          </SocialMediaContainer>
        </FlexContainer>

        <Hr />

        <Rights>{t('copyRights')}</Rights>
      </MiddleContainer>
    </Main>
  )
}
